<template>
  <Layout>
    <div class="container-fluid pt-4">
      <div class="row justify-content-around">
        <div class="col">
          <h5 class="page-header">Activities</h5>
        </div>
        <div class="col text-end">
          <BaseButton v-if="user.isTeamUser" :classes="'btn-success'" @click="createNewBrief()"> New Brief </BaseButton>
        </div>
      </div>
      <hr class="dotted-spacer" />

      <nav class="navbar navbar-light bg-light navbar-expand-sm pb-0 pt-3">
        <div class="nav nav-tabs" role="tablist">
          <a v-if="user.isAdminUser"  class="nav-link font-display" :class="{ active: tab == 'all' }" role="tab" @click="tab = 'all'">All</a>
          <a class="nav-link font-display" :class="{ active: tab == 'awaiting' }" role="tab" @click="tab = 'awaiting'"
            >Awaiting Action</a
          >
          <a class="nav-link font-display" :class="{ active: tab == 'owned' }" role="tab" @click="tab = 'owned'"
            >Owned by me</a
          >
          <a class="nav-link font-display" :class="{ active: tab == 'relevant' }" role="tab" @click="tab = 'relevant'"
            >Relevant to me</a
          >
        </div>

        <div class="text-end ms-auto mb-1">
          <BaseDatepicker v-model="startDate" name="dates" />
        </div>
      </nav>
      <div class="row pt-0">
        <div class="col">
          <!-- Loading spinner -->
          <div
            v-if="$apollo.loading"
            class="position-fixed end-0 w-100 justify-content-center"
            style="background: rgba(255, 255, 255, 0.8); height: 100vh; z-index: 1050"
          >
            <div class="text-center col pt-5 mt-5">
              <BaseSpinner />
              <p class="d-block lead fs-4 mt-5">Loading activities</p>
            </div>
          </div>
          <div v-else>
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :sort-options="{ enabled: true }"
              :pagination-options="paginationOptions"
              style-class="vgt-table condensed striped"
              @on-row-click="tableOnEditItem"
            >
              <template slot="table-row" slot-scope="props">
                <!-- standard column -->
                <span>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@layouts/main";
import { LocalGetSelf } from "@gql/user";
import { GetActivitiesByCompanyAndDatesOrdered } from "@gql/activity";

import { format, parseISO, isAfter, startOfMonth, endOfMonth, addMonths, subMonths, subDays } from "date-fns";
import { orderBy } from "lodash";

export default {
  page: {
    title: "Users",
    meta: [{ name: "description", content: "Users" }],
  },
  components: { Layout },
  data() {
    return {
      loading: true,
      startDate: {
        startDate: format(startOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd"),
        endDate: format(endOfMonth(addMonths(new Date(), 1)), "yyyy-MM-dd"),
      },
      tab: "owned",
      columns: [
        {
          label: "Start",
          field: "startDateFormatted",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "do MMM yyyy",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "End",
          field: "endDateFormatted",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "do MMM yyyy",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Brief",
          field: "title",
          filterOptions: {
            // styleClass: '', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            // placeholder: "Tag name", // placeholder for filter input
            // filterValue: 'Test', // initial populated value for this filter
            // filterDropdownItems: [], // dropdown (with selected values) instead of text input
            // filterFn: this.columnFilterFn, // custom filter function that takes two vars: data, filterString: should return true if data matches the filterString, otherwise false
            // trigger: 'enter', // only trigger on enter not on keyup
          },
        },
        {
          label: "Channel",
          field: "channelLabel",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Location",
          field: "locationLabel",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Spokesperson",
          field: "spokespersonLabel",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Status",
          field: "statusLabel",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Requestor",
          field: "createdByName",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Created",
          field: "createdAtFormatted",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "do MMM yyyy",
          width: "100px",
        },
      ],
      paginationOptions: {
        enabled: true,
        perPage: 25,
        perPageDropdown: [10, 25, 50, 100],
      },
    };
  },
  apollo: {
    user: LocalGetSelf,
    activities: {
      query: GetActivitiesByCompanyAndDatesOrdered,
      variables() {
        return {
          where: {
            company: { id: { equals: this.user.company.id } },
            startDate: {
              lte: this.startDate.endDate,
              gte: this.startDate.startDate,
            },
          },
        };
      },
      skip() {
        return !this.user;
      },
      update: function (data) {
        this.$log.debug("Got activities from API: ", data);
        return orderBy(data.activities, ["startDate"], ["desc"]);
      },
      result: function ({ data }) {
        this.loading = false;
        this.showHidden = true;
      },
      error: function (err) {
        this.$log.error(err);
      },
      fetchPolicy: "cache-and-network",
    },
  },
  computed: {
    rows: function () {
      if (!this.activities) return [];
      let activitiesToInclude = [];

      if (this.tab === "awaiting") {
        activitiesToInclude = orderBy(
          this.activities.filter((activity) => {
            if (!activity.status) {
              return false;
            }
            // const ownerIsNull = activity.brief ? !activity.brief.owner : true;
            // const ownerIsMe = activity.brief ? activity.brief.owner.person_id === this.user.id : false;
            return (
              (isAfter(parseISO(activity.endDate), subDays(new Date(), 1)) && activity.status.name === "proposed") ||
              activity.status.name === "readyforapproval"
            ); // ownerIsNull ||
          }),
          ["createdAt"],
          ["desc"]
        );
      } else if (this.tab === "owned") {
        activitiesToInclude = orderBy(
          this.activities.filter((activity) =>
            activity.brief
              ? activity.brief.owner
                ? activity.brief.owner.person
                  ? activity.brief.owner.person.id === this.user.id
                  : false
                : false
              : false
          ),
          ["createdAt"],
          ["desc"]
        );
      } else if (this.tab === "relevant") {
        activitiesToInclude = orderBy(
          this.activities.filter((activity) => {
            if (
              activity.brief &&
              activity.brief.owner &&
              activity.brief.owner.person &&
              activity.brief.owner.person.id === this.user.id
            ) {
              return true;
            }
            if (activity.brief && activity.brief.team) {
              return (
                activity.brief.team.find((teamMember) => {
                  // console.log(teamMember);
                  return teamMember.person && teamMember.person.id === this.user.id;
                }) != null
              );
            }
            return false;
          }),
          ["createdAt"],
          ["desc"]
        );
      } else {
        // tag === 'all'
        activitiesToInclude = this.activities;
      }
      return activitiesToInclude.map((activity) => ({
        ...activity,
        createdAtFormatted: format(parseISO(activity.createdAt), "yyyy-MM-dd"),
        startDateFormatted: format(parseISO(activity.startDate), "yyyy-MM-dd"),
        endDateFormatted: format(parseISO(activity.endDate), "yyyy-MM-dd"),
        title: activity.brief ? activity.brief.title : "",
        locationLabel: activity.location ? activity.location.label : "",
        spokespersonLabel: activity.spokesperson ? activity.spokesperson.label : "",
        channelLabel: activity.channel ? activity.channel.label : "",
        createdByName: activity.createdBy.name,
        statusLabel: activity.status ? activity.status.label : "No status",
      }));
    },
  },
  methods: {
    refreshTable: function () {
      this.loading = true;
      this.$apollo.queries.users.refresh();
    },
    tableOnEditItem: function (params) {
      // params.row - row object
      // params.pageIndex - index of this row on the current page.
      // params.selected - if selection is enabled this argument
      // indicates selected or not
      // params.event - click event
      this.$log.debug(params);
      if (this.user && this.user.isAdminUser) {
        this.$log.debug("tableOnEditItem - going to briefs.edit", { id: params.row.brief.id });
        this.$router.push({ name: "briefs.edit", params: { id: params.row.brief.id } });
      }
    },
    createNewBrief: function () {
      if (this.user && this.user.isAdminUser) {
        this.$log.debug("createNewUser - going to brief.new");
        this.$router.push({ name: "briefs.new" });
      }
    },
  },
};
</script>
